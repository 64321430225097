import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'mainscreen',
  component: () => import('../views/main-screen.vue')
},{
  path: '/merchant',
  name: 'merchant',
  component: () => import('../views/merchant-screen.vue')
}, {
  path: '/index',
  name: 'index',
  component: () => import('../views/index.vue')
}, {
  path: '/density',
  name: 'density',
  component: () => import('../views/index-density.vue')
}, {
  path: '/flash',
  name: 'flash',
  component: () => import('../views/index-flash.vue')
}, {
  path: '/province',
  name: 'province',
  component: () => import('../views/index-province-density.vue')
}, {
  path: '/datav',
  name: 'datav',
  component: () => import('../views/index-datav-demo.vue')
}]
const router = new VueRouter({
  mode: "hash",
  routes
})

export default router
