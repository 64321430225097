<template>
  <div name="fade"><router-view></router-view></div>
</template>

<script>
export default {
  computed: {
    key() {
      return this.$route.path + Math.random();
    }
  },
  created() {
    // console.log(document.body.clientWidth,"app.vue文件")
  }
};
</script>

<style>
html {
  font-size: 16px;
}

/* Chrome */
@media screen and (min-width: 1367px) and (max-width: 2160px) and (-webkit-min-device-pixel-ratio: 0) {
  html {
    font-size: 13px;
  }
}


</style>
