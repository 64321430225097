import store from '../store/' // vuex
import config from '../config/index' // 配置文件
export default {
  ws: null,
  websocket: false,
  conditions: '',
  time: 45000, // 发心跳时间
  clientId: '',
  clientClose: false,
  WebSocketFn: function (clientId,sign, fn) { // 连接长连接
    this.clientId = clientId
    let serverWsUrl = config.wsUrl + '?userId=' + clientId+'&sign='+sign
    console.log(serverWsUrl)
    this.ws = new WebSocket(serverWsUrl)
    this.ws.onopen = () => {
      // 连接成功
      console.log('连接成功!')
      this.websocket = true
      // if (store.state.Counter.main > 0) {
      //   store.dispatch('resetCounter')
      // }
      fn && fn(true)
      this.heartbeat()
    }
    this.ws.onerror = () => {
      // 连接失败
      console.log('连接失败!')
      this.websocket = false
      this.ws.close()
      // store.dispatch('unsuccessful')
      fn && fn(false)
    }
  },
  // 心跳
  heartbeat: function () {
    let _this = this
    setInterval(() => {
      _this.send('{"message":"心跳检测","type":5}', false)
      console.log('发送心跳--->',new Date())
    }, _this.time)
  },
  onmessage: function (fn) { // 获取服务器发送的数据
    if (!this.websocket) return
    this.ws.onmessage = (data) => {
      // console.log(data)
      data = data['data']
      let evalFn = data
      fn && fn(evalFn)
    }
  },
  evil (fn) {
    let Fn = Function // 一个变量指向Function，防止有些前端编译工具报错
    return new Fn('return ' + fn)()
  },
  send: function (data, info, fn) { // 发送消息
    if (!this.websocket) {
      fn && fn(false)
      return
    }
    this.ws.send(typeof data === 'string' ? data : JSON.stringify(data))
    fn && fn(true)
  },
  websocketclose (fn) { // 关闭
    if (!this.websocket) {
      fn && fn(false)
      return
    }
    this.websocket = false
    store.dispatch('clearMsq')
    store.dispatch('resetCounter')
    this.ws.close()
    fn && fn(true)
  }
}
