import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import WebSocket from './websocket'
import axios from 'axios'
import md5 from 'js-md5';
import { Message } from 'element-ui'

import config from './config/'

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)

//引入echart
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import './assets/map/js/china.js'

Vue.prototype.$WebSocket = WebSocket
Vue.prototype.$md5 = md5;
// 挂载到$message上
Vue.prototype.$message = Message

//挂载全局配置
Vue.prototype.$config = config

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
