const devBaseUrl = 'http://192.168.0.27:9005/admin/api'
// const devBaseUrl = 'https://qiyue.natapp4.cc/admin/api'
// const devBaseUrl = 'https://charge.shenmachong.com/admin/api'
const proBaseUrl = 'https://charge.shenmachong.com/admin/api'

const wsDevUrl = 'ws://localhost:9001/app/api/sm/web/socket'
// const wsDevUrl = 'wss://qiyue.natapp4.cc/app/api/sm/web/socket'
// const wsDevUrl = 'wss://charge.shenmachong.com/app/api/sm/web/socket'
const wsProUrl = 'wss://charge.shenmachong.com/app/api/sm/web/socket'
export default {
  /**
   * @description api请求基础路径
   */
  baseUrl: process.env.NODE_ENV === 'development' ? devBaseUrl : proBaseUrl,
  wsUrl: process.env.NODE_ENV === 'development' ? wsDevUrl : wsProUrl,
  fingerId: '11',
  merchantId: 0,
  signString:'',
}
