const state = {
  List: [],
  date: '',
}

const mutations = {
  PUSH_SOCKET_MSG: (state, args) => {
    state.List.push(args)
  },
  POP_SOCKET_MSG: (state) => {
    state.List.shift()
  },
  CLEAR_SOCKET_MSG: (state) => {
    state.List.splice(0)
  }
}

const actions = {
  addSocketMsg (context, args) {
    context.commit('PUSH_SOCKET_MSG', args)
  },
  delSocketMsg (context) {
    context.commit('POP_SOCKET_MSG')
  },
  clearMsq (context) {
    context.commit('CLEAR_SOCKET_MSG')
  }
}

export default {
  state,
  mutations,
  actions
}
